<template>
  <div
    class="contentpage"
    :class="{ topstory: siteData.topStory }"
    :style="{ '--page-color': siteData.color }"
    v-if="siteData"
    :key="siteData.id"
  >
    <div
      class="contentpage__hero"
      v-if="siteData.topStory"
      :style="{
        color: siteData.introSection.textColor,
      }"
    >
      <span class="contentpage__hero__subtitle">
        {{ siteData.subtitle }}
      </span>
      <h1>{{ siteData.leadTitle }}</h1>
      <a
        v-if="siteData.introSection.ctaButtonText"
        class="contentpage__hero__cta"
        :href="siteData.introSection.ctaButtonLink"
      >
        {{ siteData.introSection.ctaButtonText }}
      </a>
    </div>
    <router-link to="/" class="contentpage-backlink">
      <span>Home</span>
    </router-link>
    <section class="contentpage-section">
      <h1 v-if="!siteData.topStory" class="contentpage-section__headline">
        {{ siteData.subtitle }}
      </h1>
      <div v-if="!siteData.topStory" class="contentpage__lead">
        <p class="contentpage__lead__title">{{ siteData.leadTitle }}</p>
        <p class="contentpage__lead__text">{{ siteData.lead }}</p>
      </div>
      <SectionContent :blocks="siteData.content" />
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import SectionContent from '@/components/SectionContent';
import SmoothScroll from 'smooth-scroll';

export default {
  name: 'Contentpage',
  components: {
    SectionContent,
  },
  data: () => {
    return {
      siteData: null,
    };
  },
  metaInfo() {
    let _meta = [
      {
        vmid: 'description',
        name: 'description',
        content: this.siteDescription,
      },
      { vmid: 'ogurl', name: 'og:url', content: this.pageUrl },
    ];
    if (this.shareImage) {
      _meta = _meta.concat(this.shareImage);
    }

    return {
      title: this.siteData ? this.siteData.title + ' / SKKG' : '',
      meta: _meta,
    };
  },
  watch: {
    loadingCount(to, from) {
      if (to == 0) {
        this.$nextTick(() => {
          this.handleHashNavigation();
        });
      }
    },
    $route: function (to, from) {
      this.handleHashNavigation();
    },
  },
  computed: {
    subpages() {
      if (this.$route.name == 'Story') {
        return this.$store.state.stories;
      }
      return this.$store.state.contentpages;
    },
    loadingCount() {
      return this.$store.state.loadingCount;
    },
    apiUrl() {
      return this.$store.state.apiUrl;
    },
    slug() {
      return this.$route.params.slug;
    },
    siteDescription() {
      return this.siteData?.shareContent?.description
        ? this.siteData.shareContent.description
        : null;
    },
    shareImage() {
      if (this.siteData && this.siteData.shareContent?.image) {
        return [
          {
            vmid: 'ogimage',
            name: 'og:image',
            content: this.siteData.shareContent.image.url,
          },
          {
            vmid: 'ogwidth',
            name: 'og:width',
            content: this.siteData.shareContent.image.width,
          },
          {
            vmid: 'ogheight',
            name: 'og:height',
            content: this.siteData.shareContent.image.height,
          },
        ];
      } else {
        return null;
      }
    },
    /*siteData() {
      return this.subpages.find((s) => {
        return s.slug == this.slug;
      });
    },*/
  },
  methods: {
    async loadData() {
      let endpoint = 'content';
      if (this.$route.name == 'Story') {
        endpoint = 'stories';
      }

      this.$store.commit('increaseLoadingCount');
      await axios
        .get(`${this.apiUrl}${endpoint}/${this.slug}`)
        .then((data) => {
          this.$store.commit('decreaseLoadingCount');
          this.siteData = data.data;
          this.$nextTick(() => {
            this.handleHashNavigation();
          });
        })
        .catch(() => {
          this.$store.commit('decreaseLoadingCount');
          this.$router.push({ name: '404', params: { 0: this.$route.path } });
          console.log('error');
        });
    },
    handleHashNavigation() {
      if (!this.$route.hash) return;
      if (this.loadingCount) return;
      let hash = this.$route.hash;
      if (document.querySelector(hash)) {
        this.scroll.animateScroll(document.querySelector(hash), null, {
          offset: 100,
          speed: 100,
        });
      }
    },
  },
  mounted() {
    this.scroll = new SmoothScroll();
    this.$store.commit('resetFaviconUrl');
    this.loadData();
  },
};
</script>

<style lang="scss">
@import '@/scss/_helpers';

.contentpage {
  padding: 0.75rem 0;
  box-sizing: border-box;

  @include bp-s() {
    padding: 0.5rem 0;
  }

  &-backlink {
    position: fixed;
    top: 4rem;
    right: 3rem;
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    background-image: url('../assets/heart-back.svg');
    background-size: 100%;
    margin: 1rem 0 -0.5rem 0;
    z-index: 5;
    text-align: center;

    span {
      line-height: 3.2rem;
      font-size: 0.7rem;
    }
    @include bp-s() {
      display: none;
    }
  }

  &-section {
    margin: 0 0 4rem 0;

    &__headline {
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      font-size: 0.7rem;
      line-height: 1.3;
      padding: 0 0.75rem;
      margin-bottom: -0.5rem;
    }

    @include bp-s() {
      margin: 2rem 0;
    }
  }
  &__lead {
    display: block;
    padding: 0 0.75rem;
    margin-top: 1.5rem;
    max-width: 50%;

    p {
      font-size: 1.3rem;
    }
    &__title {
      font-weight: bold;
    }
    @include bp-s() {
      max-width: none;
      margin: 1rem 0;
    }
  }

  &__hero {
    margin-top: -0.75rem;
    padding: 0.5rem 0.8rem;
    padding-bottom: 8rem;
    box-sizing: border-box;
    position: relative;
    background-color: var(--page-color);

    h1 {
      font-size: 4rem;
      font-weight: bold;
      line-height: 1;
      max-width: 70vw;
      position: relative;
      margin-top: 0.5rem;
      white-space: pre-wrap;

      @include bp-l() {
        max-width: 1000px;
      }
      @media screen and (max-height: 800px) {
        // font-size: 3rem;
      }
      @include bp-s() {
        font-size: 1.8rem;
        max-width: 20rem;
      }
    }
    &__subtitle {
      text-transform: uppercase;
      letter-spacing: 0.05rem;
      font-size: 0.7rem;
      line-height: 1.3;
    }
    &__cta {
      position: absolute;
      right: 4rem;
      bottom: 4rem;
      width: 7rem;
      height: 7rem;
      font-size: 0.9rem;
      background-color: #fff;
      border-radius: 100%;
      text-align: center;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      white-space: pre-wrap;
      box-shadow: 0 0 20px 0 rgba(#000, 0);
      line-height: 1.2;
      transition: 0.3s box-shadow;

      &:hover {
        box-shadow: 0 0 8px 0 rgba(#000, 0.2);
      }

      @include bp-s() {
        display: none;
      }
    }
    @include bp-s() {
      height: auto;
      padding-top: 6rem;
      padding-bottom: 1rem;
    }
  }
}
</style>